/**
 * Module for resetting stored/persisted state after certain events
 */

import { idbStorage } from "@/lib/query-client";
import { useAppState } from "./appState";
import { useLotSearchStore } from "@/views/LotSearch/LotSearchStore";
import memory from "@/memory";

/**
 * Reset all state that should be cleared on logout
 * (this assumes store.dispatch("logout")) was also called if necessary
 */
export const resetOnLogout = async () => {
	memory.clear();
	await idbStorage.clear(); // Clear vue-query persist
	useAppState().$reset();
	useLotSearchStore().$reset();
};

export const resetOnChangeCustomer = async () => {
	await resetOnLogout();
};
