const state: { [key: string]: any } = {};

export default {
	load(name: string, data: any, keysToExclude?: Set<string>) {
		// Merge in what we've previously saved
		if (state[name]) {
			Object.entries(state[name]).forEach((entry) => {
				const key = entry[0];
				const value = entry[1];

				if (keysToExclude && keysToExclude.has(key)) return;

				data[key] = value;
			});
		}

		return data;
	},
	save(name: string, data: any) {
		if (!state[name]) {
			state[name] = data;
		} else {
			// Merge with what we've previously saved
			Object.entries(data).forEach(([key, value]) => {
				state[name][key] = value;
			});
		}
	},
	clear() {
		Object.keys(state).forEach((key) => delete state[key]);
	},
};
