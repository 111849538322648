import { MUTATIONS_MAP } from "@/lib/persisted-mutation";
import { mobileQueryClient } from "@/lib/query-client";
import { useOnline } from "@vueuse/core";
import { defineStore } from "pinia";
import { DuplicateMerger, DuplicateResult } from "seedgreen-shared/types/query.types";
import { Ref, ref, watchEffect, WatchStopHandle } from "vue";

const DEFAULT_MUTATION_EXPIRES_MS = 24 * 60 * 60 * 1000; // 1 day
const DEFAULT_DUPLICATE_MERGER = () => DuplicateResult.KeepBoth;

type OfflineMutationQueueItem = {
	api: keyof typeof MUTATIONS_MAP;
	payload: any;
	triggered: Date;
	expires: Date;
};

export type OfflineMutationQueueOptions = {
	expiresMs?: number;
	duplicateMerger?: DuplicateMerger<any>;
};

export const useOfflineMutationQueue = defineStore(
	"offlineQueue",
	() => {
		const queue = ref<OfflineMutationQueueItem[]>([]);

		function add(api: keyof typeof MUTATIONS_MAP, payload?: any, options?: OfflineMutationQueueOptions) {
			// Handle potential duplicate entries
			const duplicateMerger = options?.duplicateMerger ?? DEFAULT_DUPLICATE_MERGER;
			let discard_incoming = false;
			queue.value = queue.value.filter((item) => {
				if (item.api !== api) return true;

				const result = duplicateMerger(payload, item.payload);
				switch (result) {
					case DuplicateResult.KeepBoth:
						return true;
					case DuplicateResult.KeepIncoming:
						return false;
					case DuplicateResult.DiscardIncoming:
						discard_incoming = true;
						return true;
					case DuplicateResult.DiscardBoth:
						discard_incoming = true;
						return false;
				}
			});
			if (discard_incoming) return;

			const expires = new Date(Date.now() + (options?.expiresMs ?? DEFAULT_MUTATION_EXPIRES_MS));
			if (expires < new Date()) return;

			queue.value.push({
				api,
				payload,
				triggered: new Date(),
				expires,
			});
		}

		function pop() {
			if (!queue.value.length) return;
			return queue.value.shift();
		}

		function clear() {
			queue.value = [];
		}

		return {
			add,
			pop,
			queue,
			clear,
		};
	},
	{
		persist: true,
	},
);

let watchSingleton: WatchStopHandle | undefined = undefined;
/**
 * Register a watcher that will process offline mutations if the client goes online.
 * Will not attempt to process offline mutations until the user is both online and
 * logged in.
 * @param tokenValid Ref that should be truthy if the user is logged in.
 * @returns
 */
export function enableOfflineMutations(tokenValid: Ref<undefined | boolean>) {
	if (watchSingleton) return;

	const online = useOnline();

	watchSingleton = watchEffect(() => {
		if (online.value && tokenValid.value) {
			processOfflineMutationsQueue();
		}
	});
}

/**
 * Recursively grab and process offline mutations from the queue
 */
export async function processOfflineMutationsQueue() {
	if (!navigator.onLine) return;

	const offlineQueue = useOfflineMutationQueue();
	const item = offlineQueue.pop();
	if (!item) return;

	if (new Date(item.expires) <= new Date()) return processOfflineMutationsQueue();

	try {
		// Rebuild the saved mutation and fire it
		await mobileQueryClient
			.getMutationCache()
			.build(mobileQueryClient, MUTATIONS_MAP[item.api].mutationOptions)
			.execute(item.payload);
	} finally {
		await processOfflineMutationsQueue();
	}
}
