import { defineStore } from "pinia";
import { ref } from "vue";

/**
 * Misc store for app state
 * Data in here is saved to localStorage, but unlike persistedState, this store is cleared on logout
 */
export const useAppState = defineStore(
	"appState",
	() => {
		// Current "home" route that the app will return to on re-opening
		const homeRoute = ref<string | undefined>();

		function $reset() {
			homeRoute.value = undefined;
		}

		return {
			homeRoute,
			$reset,
		};
	},
	{
		persist: true,
	},
);
