import { createStore, StoreOptions } from "vuex";
import VuexPersist from "vuex-persist";
import localforage from "localforage";
import OfflineQueue from "@/api/offline-queue";
import BackgroundSync from "@/api/background-sync";
import moment from "moment";
import { getDeviceLanguage } from "@/lib/locale";
import { IsoWeekOfYear, Jan1WeekOfYear, Aug28WeekOfYear } from "seedgreen-shared-scripts";
import { SoilSampleTask } from "seedgreen-shared/models/SoilSampleTask";
import { ChemicalTask } from "seedgreen-shared/models/ChemicalTask";
import { CustomerParameterType } from "seedgreen-shared/models/CustomerParameter";
import { AuthRequestUser } from "seedgreen-shared/models/AuthRequestUser";
import { PlantingRegionAlert } from "seedgreen-shared/models/PlantingRegionAlert";

const vuexPersist = new VuexPersist({
	key: "app-data",
	// @ts-ignore: FIXME: type failure?
	storage: localforage,
	asyncStorage: true,
	reducer: (state: any) => {
		const ignoredKeys = new Set(["online", "currentLocation"]);

		return Object.keys(state).reduce((acc: any, key) => {
			if (!ignoredKeys.has(key)) acc[key] = state[key];

			return acc;
		}, {});
	},
});

type State = {
	online: boolean;
	offlineQueue: any[]; // FIXME
	loggedIn: boolean;
	currentLocation: any | null; // FIXME
	/**
	 * The last time that we synced with the server.
	 * If null, store data isn't valid.
	 */
	lastSync: Date | null;

	soilSampleTasks: SoilSampleTask[];
	chemicalTasks: ChemicalTask[];
	notesById: Record<string, any>;
	noteIdsByPlantingIds: Record<number, number[]>;
	customerParameters: Record<keyof typeof CustomerParameterType, string>;
	regions: any[]; // FIXME
	user: AuthRequestUser | undefined;
	customerCommodityAliases: Record<number, string>;
	alerts: PlantingRegionAlert[];
	permissions: Record<string, boolean>;
};

function getDefaultState() {
	return {
		yearWeekType: "Iso",
		loggedIn: false,
		deviceRegistered: false,
		online: navigator.onLine,
		currentLocation: null,
		locale: getDeviceLanguage(),
		offlineQueue: [],
		lastSync: null,
		// Data from server:
		user: {} as AuthRequestUser,
		customer: {},
		regions: [], // List of plantings
		noteCategories: [],
		alerts: [],
		certainties: {},
		coolers: [],
		cropTypes: [],
		customerUnits: {},
		unitsById: {},
		customerCommodityAliases: {},
		customerParameters: {} as Record<keyof typeof CustomerParameterType, string>,
		customerPreferences: {},
		binTypes: [],
		headSizeColorMaps: {}, // Dictionary of GAC bin -> RGB color maps, keyed off of planting IDs
		sizingGuides: {},
		notesById: {},
		noteIdsByPlantingIds: {},
		environmentRegions: [],
		groupedObservationSubTypes: {},
		irrigations: [],
		irrigationBlocksById: {},
		irrigationMethods: {
			"1": "irrigation.methods.drip",
			"2": "irrigation.methods.sprinkler",
		},
		soilSampleTasks: [],
		soilSampleTypes: {},
		chemicalTasks: [],
		chemicalFormulas: [],
		lotViewsById: {},
		noteUrgencyTypesById: {},
		nurseriesById: {},
		needsToSignAgreement: true,
		observationTypesById: {},
		observationSubTypes: {},
		observationCategoriesById: {},
		observationScalesById: {},
		permissions: {},
		plantingTicketsByPlantingId: {},
		ranches: [],
		ranchCoolers: [],
		ranchYieldStats: [],
		affectedAcres: [],
		regionalYieldStatTypes: {},
		seedSourcesById: {},
		seedInfoPresetsById: {},
		fertilizerSuppliersById: {},
		fertilizerTypesById: {},
		shippersById: {},
		sublotViewsById: {},
		tasks: [],
		taskRequestsById: {},
		tasksFilters: {
			"tasks.list": {
				status: "all",
				type: ["all"],
				sort: "dateDsc",
				user: "all",
				date: { selectionType: "date", date: moment().format("YYYY-MM-DD") },
				location: null,
			},
			"plantings.details": {
				status: "all",
				type: ["all"],
				sort: "dateDsc",
				user: "all",
				date: null,
				location: null,
			},
		},
		irrigationFilters: {
			sort: "timeRemaining",
			crop: "all",
			location: null,
		},
		displayUsersById: {},
	} as State & Record<string, any>;
}

const mutations = {
	RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION, //refreshes the store after localforage loads up
	setOnline(state: { online: any }, isOnline: any) {
		state.online = isOnline;
	},
	setCurrentLocation(
		state: { currentLocation: any },
		currentLocation: { coords: { accuracy_feet: number; accuracy: number } },
	) {
		currentLocation.coords.accuracy_feet = Math.round(currentLocation.coords.accuracy * 3.28084);
		state.currentLocation = currentLocation;
	},
	addToOfflineQueue(
		state: { offlineQueue: { endpoint: any; payload: any }[] },
		payload: { duplicateMerger: (arg0: any) => any; endpoint: any; payload: any },
	) {
		if (payload.duplicateMerger) {
			let includePayload = true;

			state.offlineQueue = state.offlineQueue.filter((queueItem: any) => {
				const action = payload.duplicateMerger(queueItem);

				switch (action) {
					// -1: Don't include new payload
					case -1:
						includePayload = false;
						return true;
					// 1: Don't include current item
					case 1:
						return false;
					// 2: Don't include either items
					case 2:
						includePayload = false;
						return false;
					// Otherwise, can include new payload and current item
					default:
						return true;
				}
			});

			if (includePayload) {
				state.offlineQueue.push({ endpoint: payload.endpoint, payload: payload.payload });
			}
		} else {
			state.offlineQueue.push({ endpoint: payload.endpoint, payload: payload.payload });
		}
	},
	removeFromOfflineQueue(state: { offlineQueue: void[] }) {
		state.offlineQueue.shift();
	},
	login(state: State) {
		state.loggedIn = true;
	},
	setPermissions(state: { permissions: any }, permissions: any[]) {
		state.permissions = permissions.reduce(
			(a: { [x: string]: boolean }, k: string | number) => ((a[k] = true), a),
			{},
		);
	},
	setNeedsToSignAgreement(state: { needsToSignAgreement: any }, value: any) {
		state.needsToSignAgreement = value;
	},
	updateUserAgreement(state: { needsToSignAgreement: boolean }) {
		state.needsToSignAgreement = false;
	},
	deleteAlert(state: { alerts: any[] }, alertId: any) {
		state.alerts = state.alerts.filter((alert: { id: any }) => alert.id !== alertId);
	},
	clearAlerts(state: { alerts: never[] }) {
		state.alerts = [];
	},
	commitRanchYield(state: { ranchYieldStats: any[] }, payload: { tempId: string }) {
		const obj = state.ranchYieldStats.find((x: { tempId: any }) => x.tempId == payload.tempId);
		if (!obj) throw "Ranch Yield not found by tempId: " + payload.tempId;
		delete obj.tempId;
	},
	createRanchYield(state: { ranchYieldStats: any[] }, payload: { value: any }) {
		function dateEqual(a: any, b: any) {
			const a_str = a instanceof Date ? a.toISOString() : a;
			const b_str = b instanceof Date ? b.toISOString() : b;
			return a_str.slice(0, 10) === b_str.slice(0, 10);
		}

		function compareStat(
			a: {
				statisticTypeId: any;
				date: any;
				ranchId: any;
				cropId: any;
				certaintyId: any;
				organicStatusId: any;
				timeframe: any;
			},
			b: {
				statisticTypeId: any;
				date: any;
				ranchId: any;
				cropId: any;
				certaintyId: any;
				organicStatusId: any;
				timeframe: any;
			},
		) {
			return (
				a.statisticTypeId == b.statisticTypeId &&
				dateEqual(a.date, b.date) &&
				a.ranchId == b.ranchId &&
				a.cropId == b.cropId &&
				a.certaintyId == b.certaintyId &&
				a.organicStatusId == b.organicStatusId &&
				a.timeframe == b.timeframe
			);
		}
		const newStat = payload.value;
		const oldStatIndex = state.ranchYieldStats.findIndex((oldStat: any) => compareStat(oldStat, newStat));

		if (oldStatIndex != -1) {
			const oldStat = state.ranchYieldStats[oldStatIndex];
			if (!compareStat(oldStat, newStat)) {
				alert("Something went wrong while updating your ranch yield data. Please resync.");

				console.group("store.createRanchYield");
				console.error("Old stat", oldStat);
				console.error("New stat", newStat);
				console.groupEnd();

				return;
			}

			state.ranchYieldStats.splice(oldStatIndex, 1);
		}

		state.ranchYieldStats.push(payload.value);
	},
	commitIrrigation(state: { irrigations: any[] }, payload: { tempId: string; id: any }) {
		console.debug("store.commitIrrigation", JSON.stringify(payload));
		const irr = state.irrigations.find((x: { tempId: any }) => x.tempId == payload.tempId);
		if (!irr) throw "Irrigation not found by tempId: " + payload.tempId;
		irr.id = payload.id;
		delete irr.tempId;
	},
	createIrrigation(state: any, payload: { value: any }) {
		console.debug("store.createIrrigation", JSON.stringify(payload));
		state.irrigations.push(payload.value);
	},
	updateIrrigation(state: any, payload: { value: { id: any; hash: any } }) {
		console.debug("store.updateIrrigation", JSON.stringify(payload));
		// We want to look for the hash first, because the internal model may have a hash without an id, and the response from the server could have the id.
		const getId = (irrigationTask: any) =>
			irrigationTask.hash || irrigationTask.id || irrigationTask.virtualEventHash;
		const irrigationTaskIndex = state.irrigations.findIndex(
			(irrigationTask: any) => getId(irrigationTask) == getId(payload.value),
		);

		if (irrigationTaskIndex == -1) {
			// Just create it. It's a virtual task.
			state.irrigations.push(payload.value);
			return;
		}

		if (payload.value.id && payload.value.hash) {
			delete payload.value.hash; //no longer need the hash
		}
		state.irrigations[irrigationTaskIndex] = payload.value;
	},
	deleteIrrigation(state: { irrigations: any[] }, payload: { value: { id: string } }) {
		console.debug("store.deleteIrrigation", JSON.stringify(payload));
		const i = state.irrigations.findIndex((x: { id: any }) => x.id == payload.value.id);
		if (i == -1) throw "Irrigation not found by id: " + payload.value.id;
		state.irrigations.splice(i, 1);
	},
	commitIrrigationActivity(
		state: { irrigations: any[] },
		payload: { taskId: string; tempId: any; start: any; end: any; id: any },
	) {
		console.debug("store.commitIrrigationActivity", JSON.stringify(payload));
		const irr = state.irrigations.find((x: { id: any }) => x.id == payload.taskId);
		if (!irr) throw "Irrigation not found by id: " + payload.taskId;
		let activity = irr.activity.find((x: { tempId: any }) => x.tempId == payload.tempId);
		if (!activity) {
			activity = irr.activity.find(
				(x: { start: any; end: any }) => x.start == payload.start && x.end == payload.end,
			);
			// if we don't find it, assume it was removed.
		}

		activity.id = payload.id;
		delete activity.tempId;
	},
	createIrrigationActivity(state: { irrigations: any[] }, payload: { value: { taskId: string } }) {
		console.debug("store.createIrrigationActivity", JSON.stringify(payload));
		const irr = state.irrigations.find((x: { id: any }) => x.id == payload.value.taskId);
		if (irr == -1) throw "Irrigation not found by id: " + payload.value.taskId;
		irr.activity.push(payload.value);
	},
	updateIrrigationActivity(state: { irrigations: any[] }, payload: { value: { taskId: string; id: string } }) {
		console.debug("store.updateIrrigationActivity", JSON.stringify(payload));
		const irr = state.irrigations.find((x: { id: any }) => x.id == payload.value.taskId);
		if (!irr) throw "Irrigation not found by id: " + payload.value.taskId;

		const i = irr.activity.findIndex((x: { id: any }) => x.id == payload.value.id);
		if (i == -1) throw "Irrigation activity not found by id: " + payload.value.id;

		irr.activity[i] = payload.value;
	},
	deleteIrrigationActivity(state: { irrigations: any[] }, payload: { value: { taskId: string; id: string } }) {
		const irr = state.irrigations.find((x: { id: any }) => x.id == payload.value.taskId);
		if (!irr) throw "Irrigation not found by id: " + payload.value.taskId;

		const i = irr.activity.findIndex((x: { id: any }) => x.id == payload.value.id);
		if (i == -1) throw "Irrigation activity not found by id: " + payload.value.id;
		irr.activity.splice(i, 1);
	},
	commitSoilSampleTask(state: State, payload: SoilSampleTask) {
		console.debug("store.commitSoilSampleTask", JSON.stringify(payload));
		const task = state.soilSampleTasks.find((x) => x.virtualId === payload.virtualId);
		if (!task) throw "Soil sample task not found by virtualId: " + payload.virtualId;
		task.id = payload.id as unknown as number; // Mobile app does not yet use the class itself, so id is wrong type
		task.virtualId = undefined;
	},
	createSoilSampleTask(state: State, payload: SoilSampleTask) {
		console.debug("store.createSoilSampleTask", JSON.stringify(payload));
		state.soilSampleTasks.push(payload);
	},
	updateSoilSampleTask(state: State, payload: SoilSampleTask) {
		console.debug("store.updateSoilSampleTask", JSON.stringify(payload));
		// We want to look for the hash first, because the internal model may have a hash without an id, and the response from the server could have the id.
		const getId = (task: SoilSampleTask) => task.virtualId || task.id;
		const taskIndex = state.soilSampleTasks.findIndex((task) => getId(task) === getId(payload));

		if (taskIndex === -1) {
			// Just create it. It's a virtual task.
			state.soilSampleTasks.push(payload);
			return;
		}

		if (payload.id && payload.virtualId) payload.virtualId = undefined;

		state.soilSampleTasks[taskIndex] = payload;
	},
	deleteSoilSample(state: State, payload: SoilSampleTask) {
		console.debug("store.deleteSoilSample", JSON.stringify(payload));
		const i = state.soilSampleTasks.findIndex((x) => x.id == payload.id);

		if (i == -1) throw "Soil sample task not found by id: " + payload.id;

		state.soilSampleTasks.splice(i, 1);
	},
	commitChemicalTask(state: State, payload: ChemicalTask) {
		console.debug("store.commitChemicalTask", JSON.stringify(payload));
		const task = state.chemicalTasks.find((x) => x.virtualId === payload.virtualId);
		if (!task) throw "Chemical task not found by virtualId: " + payload.virtualId;
		task.id = payload.id as unknown as number; // Mobile app does not yet use the class itself, so id is wrong type
		task.virtualId = undefined;
	},
	createChemicalTask(state: State, payload: ChemicalTask) {
		console.debug("store.createChemicalTask", JSON.stringify(payload));
		state.chemicalTasks.push(payload);
	},
	updateChemicalTask(state: State, payload: ChemicalTask) {
		console.debug("store.updateChemicalTask", JSON.stringify(payload));
		// We want to look for the hash first, because the internal model may have a hash without an id, and the response from the server could have the id.
		const getId = (task: ChemicalTask) => task.virtualId || task.id;
		const taskIndex = state.chemicalTasks.findIndex((task) => getId(task) === getId(payload));

		if (taskIndex === -1) {
			// Just create it. It's a virtual task.
			state.chemicalTasks.push(payload);
			return;
		}

		if (payload.id && payload.virtualId) payload.virtualId = undefined;

		state.chemicalTasks[taskIndex] = payload;
	},
	deleteChemicalTask(state: State, payload: ChemicalTask) {
		console.debug("store.deleteChemicalTask", JSON.stringify(payload));
		const i = state.chemicalTasks.findIndex((x) => x.id == payload.id);

		if (i == -1) throw "Chemical task not found by id: " + payload.id;

		state.chemicalTasks.splice(i, 1);
	},
	addNote(
		state: { notesById: { [x: string]: any }; noteIdsByPlantingIds: { [x: string]: any[] } },
		note: { noteId: any; _noteId: any; plantingIds: any[] },
	) {
		// Update notesById
		state.notesById[note.noteId || note._noteId] = note;

		// Update noteIdsByPlantingIds
		note.plantingIds.forEach((plantingId: string | number) => {
			if (!state.noteIdsByPlantingIds[plantingId]) state.noteIdsByPlantingIds[plantingId] = [];

			state.noteIdsByPlantingIds[plantingId].unshift(note.noteId || note._noteId);
		});
	},
	deleteNote(
		state: { notesById: { [x: string]: any }; noteIdsByPlantingIds: { [x: string]: any[] } },
		noteToDelete: { _noteId: string | number; noteId: string | number; plantingIds: any[] },
	) {
		// Update notesById
		delete state.notesById[noteToDelete._noteId];
		delete state.notesById[noteToDelete.noteId];

		// Update noteIdsByPlantingIds
		noteToDelete.plantingIds.forEach((plantingId: string | number) => {
			state.noteIdsByPlantingIds[plantingId] = state.noteIdsByPlantingIds[plantingId].filter(
				(noteId: any) => noteId !== noteToDelete._noteId && noteId !== noteToDelete.noteId,
			);
		});
	},
	editNote(
		state: { notesById: { [x: string]: any }; noteIdsByPlantingIds: { [x: string]: any[] } },
		editedNote: { [x: string]: any; noteId: any; _noteId: any; plantingIds: any[] },
	) {
		const note = state.notesById[editedNote.noteId || editedNote._noteId];

		if (note) {
			// Update noteIdsByPlantingIds by removing the old associations and adding the current associations
			note.plantingIds.forEach((plantingId: string | number) => {
				state.noteIdsByPlantingIds[plantingId] = state.noteIdsByPlantingIds[plantingId].filter(
					(noteId: any) => noteId !== editedNote._noteId && noteId !== editedNote.noteId,
				);
			});

			editedNote.plantingIds.forEach((plantingId: string | number) => {
				if (!state.noteIdsByPlantingIds[plantingId]) state.noteIdsByPlantingIds[plantingId] = [];

				state.noteIdsByPlantingIds[plantingId].unshift(note.noteId || note._noteId);
			});

			// Update note
			const keysToUpdate = [
				"noteTypeId",
				"noteText",
				"location",
				"accuracy",
				"observations",
				"plantingIds",
				"noteUrgencyId",
			];

			// Overwrite with the updated key-values
			keysToUpdate.forEach((key) => {
				note[key] = editedNote[key];
			});
		}
	},
	// Fill in noteId of note with matching _noteId, and update the createdDate
	commitNote(
		state: { notesById: { [x: string]: any }; noteIdsByPlantingIds: { [x: string]: any[] } },
		payload: {
			_noteId: string | number;
			noteId: string | number;
			noteImages: any;
			plantingIds: any;
			createdDate: any;
			observations: any;
		},
	) {
		const note = state.notesById[payload._noteId];

		if (note) {
			// Update notesById
			state.notesById[payload.noteId] = note;
			delete state.notesById[payload._noteId];

			// Update noteIdsByPlantingIds by swapping out _noteId with noteId
			note.plantingIds.forEach((plantingId: string | number) => {
				state.noteIdsByPlantingIds[plantingId] = state.noteIdsByPlantingIds[plantingId].map((noteId: any) =>
					noteId === payload._noteId ? payload.noteId : noteId,
				);
			});

			// Update note
			note.noteId = payload.noteId;
			note.noteImages = payload.noteImages;
			note.plantingIds = payload.plantingIds;
			note.createdDate = payload.createdDate;
			note.observations = payload.observations;

			delete note._noteId;
		}
	},
	// Add an image to a note's list of images
	addNoteImage(state: { notesById: { [x: string]: any } }, payload: { noteId: string | number; noteImage: any }) {
		const note = state.notesById[payload.noteId];
		const noteImage = payload.noteImage;

		if (note) note.noteImages = note.noteImages.concat(noteImage);
	},
	clearUserStatistic(state: { regions: any[] }, payload: { plantingId: any; valueType: string | number }) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting && planting.userStatistics && planting.userStatistics.statistics)
			delete planting.userStatistics.statistics[payload.valueType];
	},
	clearUserStatistics(state: { regions: any[] }, plantingId: any) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === plantingId);

		if (planting) delete planting.userStatistics;
	},
	updateUserStatistic(
		state: { regions: any[] },
		payload: { plantingId: any; valueType: string | number; value: any },
	) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting) {
			if (!planting.userStatistics) planting.userStatistics = { statistics: {} };

			planting.userStatistics.statistics[payload.valueType] = { value: payload.value };
		}
	},
	updateActualStatistic(
		state: { regions: any[] },
		payload: { plantingId: any; valueType: string | number; value: any },
	) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting) {
			if (!planting.actualStatistics) planting.actualStatistics = { statistics: {} };

			planting.actualStatistics.statistics[payload.valueType] = { value: payload.value };
		}
	},
	updateBatchStatistics(state: { regions: any[] }, payload: { plantingId: any; binData: any[] }) {
		console.debug("updateBatchStatistics", JSON.stringify(payload));
		const lc = (x: string | any[]) => x[0].toLowerCase() + x.slice(1);
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (!planting) return;

		const hasUserVal = !payload.binData.map((x: { userValue: any }) => x.userValue).every((x: null) => x === null);

		if (hasUserVal) {
			// Make sure the requisite objects exist
			if (!planting.userStatistics) planting.userStatistics = {};
			if (!planting.userStatistics.statistics) planting.userStatistics.statistics = {};
			// Update bins
			payload.binData.forEach(({ binName, userValue }) => {
				// Initialize statistic if necessary
				if (!planting.userStatistics.statistics[lc(binName)])
					planting.userStatistics.statistics[lc(binName)] = {};

				const oldValue = planting.userStatistics.statistics[lc(binName)].value;

				// Set statistic value
				planting.userStatistics.statistics[lc(binName)].value = userValue;

				const statistic = planting.userStatistics.statistics[lc(binName)];

				// Rescale HarvestAcresValues based on the primary bin if using "units" BinType
				if (
					statistic.binType &&
					statistic.binType.isPrimaryBin &&
					planting.harvestAcres &&
					planting.harvestAcres.length &&
					planting.harvestAcres[0].binType === "units"
				) {
					planting.harvestAcres.forEach(
						(x: { acres: number }) => (x.acres = Math.round((x.acres * userValue) / oldValue)),
					);
				}
			});
		} else {
			planting["userStatistics"] = null;
		}

		const hasActualVal = !payload.binData
			.map((x: { actualValue: any }) => x.actualValue)
			.every((x: null) => x === null);

		if (hasActualVal) {
			// Make sure the requisite objects exist
			if (!planting.actualStatistics) planting.actualStatistics = {};
			if (!planting.actualStatistics.statistics) planting.actualStatistics.statistics = {};

			// Update bins
			payload.binData.forEach(({ binName, actualValue }) => {
				planting.actualStatistics.statistics[lc(binName)] = { value: actualValue };
			});
		} else {
			planting["actualStatistics"] = null;
		}
	},
	setUserStatistics(state: { regions: any[] }, payload: { plantingId: any; userStatistics: any }) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting) planting["userStatistics"] = payload.userStatistics;
	},
	setHarvestAcres(state: { regions: any[] }, payload: { plantingId: any; harvestAcres: any }) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting) planting["harvestAcres"] = payload.harvestAcres;
	},
	updateHarvestAcres(state: { regions: any[] }, payload: { plantingId: any; harvestAcres: any[]; clearFlex: any }) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting) {
			planting["harvestAcres"] = payload.harvestAcres;

			// If using `BinType` of "units", also update the user's forecast for the primary bin
			if (payload.harvestAcres && payload.harvestAcres.length && payload.harvestAcres[0].binType === "units") {
				const totalCartons = payload.harvestAcres.reduce(
					(acc: number, x: { acres: string | number }) => acc + +x.acres,
					0,
				);

				if (planting.userStatistics && planting.userStatistics.statistics) {
					const primaryStatistic: any = Object.values(planting.userStatistics.statistics).find(
						(x: any) => x.binType && x.binType.isPrimaryBin,
					);

					if (primaryStatistic) primaryStatistic["value"] = totalCartons;
				}
			}

			if (payload.clearFlex) planting["flexAcres"] = null;
		}
	},
	setIrrigationBlocks(state: { irrigationBlocksById: { [x: string]: any } }, payload: string | any[]) {
		state.irrigationBlocksById = {};
		for (let i = 0; i < payload.length; i++) {
			const block = payload[i];
			state.irrigationBlocksById[block.id] = block;
		}
	},
	putTask(state: { tasks: any[] }, payload: { id: any; virtualId: null }) {
		// Find task
		let idx = -1;
		if (payload.id) idx = state.tasks.findIndex((t: { id: any }) => t.id === payload.id);
		if (idx === -1 && payload.virtualId)
			idx = state.tasks.findIndex((t: { virtualId: any }) => t.virtualId === payload.virtualId);

		if (payload.virtualId && payload.id) payload.virtualId = null;

		// Create
		if (idx === -1) {
			state.tasks.push(payload);
		}
		// Update
		else {
			state.tasks[idx] = payload;
		}
	},
	savePlantingTicketRecord(
		state: { plantingTicketsByPlantingId: { [x: string]: { [x: string]: any } } },
		payload: { plantingId: any; virtualId: null; id: any },
	) {
		const pid = payload.plantingId;
		if (!pid) return;

		// Get planting's record list
		if (!state.plantingTicketsByPlantingId[pid]) state.plantingTicketsByPlantingId[pid] = [];
		const records = state.plantingTicketsByPlantingId[pid];

		// Find the record if exists already
		let idx;
		if (payload.virtualId) idx = records.findIndex((x: { virtualId: any }) => x.virtualId === payload.virtualId);
		else idx = records.findIndex((x: { id: any }) => x.id === payload.id);

		if (payload.id) payload.virtualId = null;

		// If no existing record, see if we should update a draft instead
		// (since submitted records are immutable, we won't be updating those)
		if (idx === -1) {
			idx = records.findIndex((r: { submittedDate: any }) => !r.submittedDate);
			if (idx) console.debug("store::savePlantingTicketRecord: id/vid lookup miss, but draft found", payload);
		}

		// Save
		if (idx >= 0) state.plantingTicketsByPlantingId[pid][idx] = payload;
		else records.push(payload);
	},
	deletePlantingTicketRecord(
		state: { plantingTicketsByPlantingId: { [x: string]: any[] } },
		payload: { plantingId: any; virtualId: any; id: any },
	) {
		const pid = payload.plantingId;
		if (!pid) return;

		// Get planting's record list
		const records = state.plantingTicketsByPlantingId[pid];
		if (!records) return;

		// Find the record if exists already
		let idx;
		if (payload.virtualId) idx = records.findIndex((x: { virtualId: any }) => x.virtualId === payload.virtualId);
		else idx = records.findIndex((x: { id: any }) => x.id === payload.id);

		// Remove record
		if (idx >= 0) delete state.plantingTicketsByPlantingId[pid];
	},
	deletePlantingTicketDrafts(state: { plantingTicketsByPlantingId: { [x: string]: any[] } }, payload: any) {
		const pid = payload;
		if (!pid) return;

		// Get planting's record list
		const records = state.plantingTicketsByPlantingId[pid];
		if (!records) return;

		// Filter out the un-submitted ones
		state.plantingTicketsByPlantingId[pid] = state.plantingTicketsByPlantingId[pid].filter(
			(r: { submittedDate: any }) => r.submittedDate,
		);
	},
	submitPlantingTicketRecord(
		state: { plantingTicketsByPlantingId: { [x: string]: any[] }; regions: any[] },
		payload: { plantingId: any; id: any; virtualId: any; wetDate: any },
	) {
		const pid = payload.plantingId;
		if (!pid) return;

		const record = state.plantingTicketsByPlantingId[pid].find(
			(x: { id: any; virtualId: any }) => x.id === payload.id || x.virtualId === payload.virtualId,
		);
		if (!record) return;
		// Set the submitted date so the record isn't editable anymore
		record.submittedDate = moment.utc().toDate();

		const planting = state.regions.find((x: { id: any }) => x.id === pid);
		if (!planting) return;
		// Set the actual wet date of the planting so it shows as planted
		planting._wetDateActual_beforeTicketSubmit = planting.wetDateActual;
		planting.wetDateActual = payload.wetDate;
	},
	revertSubmitPlantingTicketRecord(
		state: { plantingTicketsByPlantingId: { [x: string]: any[] }; regions: any[] },
		payload: { plantingId: any; id: any; virtualId: any },
	) {
		const pid = payload.plantingId;
		if (!pid) return;

		const record = state.plantingTicketsByPlantingId[pid].find(
			(x: { id: any; virtualId: any }) => x.id === payload.id || x.virtualId === payload.virtualId,
		);
		if (!record) return;
		// Remove the submitted date so the record is visible again
		record.submittedDate = null;

		const planting = state.regions.find((x: { id: any }) => x.id === pid);
		if (!planting) return;
		// Revert the actual wet date so the planting doesn't show as planted
		planting.wetDateActual = planting._wetDateActual_beforeTicketSubmit;
	},
	updatePlantingAfterSubmitTicket(state: { regions: any[] }, payload: { id: string }) {
		if (!payload.id) return;

		const i = state.regions.findIndex((x: { id: any }) => x.id === payload.id);
		if (i == -1) throw "Planting not found by id: " + payload.id;

		state.regions[i] = payload;
	},
	createOrUpdateTaskRequests(state: { taskRequestsById: { [x: string]: any } }, payload: any[]) {
		if (!payload) return;

		// Add/update the task request
		payload.forEach((taskRequest: { id: string | number }) => {
			// We can't add a task request to the dictionary without an id
			if (!taskRequest.id) return;

			state.taskRequestsById[taskRequest.id] = taskRequest;
		});
	},
	setSeasons(state: { seasons: any }, payload: any) {
		state.seasons = payload;
	},
	setRanches(state: { ranches: any }, payload: any) {
		state.ranches = payload;
	},
	setRanchCoolers(state: { ranchCoolers: any }, payload: any) {
		state.ranchCoolers = payload;
	},
	setHeadSizeColorMap(
		state: { headSizeColorMaps: { [x: string]: any }; sizingGuides: { [x: string]: any } },
		payload: { plantingId: string | number; colorMap: any; sizingGuide: any },
	) {
		state.headSizeColorMaps[payload.plantingId] = payload.colorMap;

		if (!state.headSizeColorMaps[payload.plantingId].colorMap) delete state.headSizeColorMaps[payload.plantingId];

		state.sizingGuides[payload.plantingId] = payload.sizingGuide;

		if (!state.sizingGuides[payload.plantingId].sizingGuide) delete state.sizingGuides[payload.plantingId];
	},
	loadPlantingDistribution(
		state: { regions: any[] },
		payload: { plantingId: any; distribution: { [x: string]: any } },
	) {
		const planting = state.regions.find((planting: { id: any }) => planting.id === payload.plantingId);

		if (planting && planting.statistics) {
			Object.keys(planting.statistics.statistics).forEach((key) => {
				if (payload.distribution[key]) planting.statistics.statistics[key]["value"] = payload.distribution[key];
			});
		}
	},
	tasksFiltersChanged(state: { tasksFilters: any }, payload: any) {
		if (!payload) return;

		state.tasksFilters = { ...state.tasksFilters, ...payload };
	},
	irrigationFiltersChanged(state: { irrigationFilters: any }, payload: any) {
		if (!payload) return;

		state.irrigationFilters = { ...state.irrigationFilters, ...payload };
	},
	logout(state: { [x: string]: any }) {
		const defaultState: any = getDefaultState();
		const keysToSkip = new Set(["currentLocation", "locale"]);

		// Reset state to default
		Object.keys(defaultState).forEach((key) => {
			if (!keysToSkip.has(key)) state[key] = defaultState[key];
		});

		// Purge any deprecrated keys
		Object.keys(state).forEach((key) => {
			if (defaultState[key] === undefined) delete state[key];
		});

		state.loggedIn = false;
		state.lastSync = null; // Data no longer valid
	},
	setLocale(state: { locale: any }, locale: any) {
		state.locale = locale;
	},
	setLastSync(state: State, lastSync: any) {
		state.lastSync = lastSync;
	},
	resync(state: State & Record<string, any>, data: any) {
		state.yearWeekType = data.yearWeekType;

		state.user = data.user;
		state.locale = data.user.locale || getDeviceLanguage();
		state.regions = data.regions;

		// We build the dict keys here on the mobile app side, so they don't get lower-cased by .NET
		state.lotViewsById = Object.fromEntries(data.lotViews.map((l: { uid: any }) => [l.uid, l]));
		state.sublotViewsById = Object.fromEntries(data.sublotViews.map((x: { uid: any }) => [x.uid, x]));

		state.noteCategories = data.noteCategories;
		state.notesById = data.notesById;
		state.noteIdsByPlantingIds = data.noteIdsByPlantingIds;
		state.noteUrgencyTypesById = data.noteUrgencyTypesById;

		state.alerts = data.alerts;
		state.certainties = data.certainties;
		state.coolers = data.coolers;
		state.cropTypes = data.cropTypes;
		state.customerUnits = data.customerUnits;
		state.unitsById = data.unitsById;
		state.customer = data.customer;
		state.customerCommodityAliases = data.customerCommodityAliases;
		state.customerParameters = data.customerParameters;
		state.customerPreferences = data.customerPreferences;
		state.binTypes = data.binTypes;
		state.groupedObservationSubTypes = data.groupedObservationSubTypes;
		state.observationTypesById = data.observationTypesById;
		state.observationSubTypes = data.observationSubTypes;
		state.observationCategoriesById = data.observationCategoriesById;
		state.observationScalesById = data.observationScalesById;
		state.permissions = data.permissions.reduce(
			(a: { [x: string]: boolean }, k: string | number) => ((a[k] = true), a),
			{},
		);
		state.ranchYieldStats = data.ranchYieldStats;
		state.affectedAcres = data.affectedAcres;
		state.ranches = data.ranches;
		state.environmentRegions = data.environmentRegions;
		state.regionalYieldStatTypes = data.regionalYieldStatTypes;
		state.weedTypesById = data.weedTypesById;

		// add a unique id for the virtual tasks.
		// Doesn't matter if it stays in sync with the state of the task.
		// Just needs to be unique to avoid update errors for items rendered in vue lists
		data.irrigations
			.filter((irrigation: { id: any }) => !irrigation.id)
			.forEach(
				(t: { hash: string; location: { irrigationBlockId: any; plantingIds: any[] }; scheduledDate: any }) =>
					(t.hash = `${t.location?.irrigationBlockId}-${t.location?.plantingIds?.[0]}-${t.scheduledDate}`),
			);
		state.irrigations = data.irrigations;

		state.soilSampleTasks = data.soilSampleTasks;

		state.soilSampleTypes = data.soilSampleTypes;

		state.chemicalTasks = data.chemicalTasks;
		state.chemicalFormulas = data.chemicalFormulas;

		state.needsToSignAgreement = data.needsToSignAgreement;

		state.tasks = data.tasks;
		state.taskRequestsById = data.taskRequestsById;

		state.plantingTicketsByPlantingId = data.plantingTicketsByPlantingId;
		state.shippersById = data.shippersById;
		state.nurseriesById = data.nurseriesById;
		state.seedSourcesById = data.seedSourcesById;
		state.seedInfoPresetsById = data.seedInfoPresetsById;
		state.fertilizerSuppliersById = data.fertilizerSuppliersById;
		state.fertilizerTypesById = data.fertilizerTypesById;

		state.scheduleColorsByTaskType = data.scheduleColorsByTaskType;

		state.displayUsersById = data.displayUsersById;

		// Purge any head size color maps not associated with the current plantings
		const plantingIds = new Set(state.regions.map((planting: { id: any }) => planting.id));

		Object.keys(state.headSizeColorMaps).forEach((plantingId) => {
			if (!plantingIds.has(plantingId)) {
				delete state.headSizeColorMaps[plantingId];
			}
		});

		Object.keys(state.sizingGuides).forEach((plantingId) => {
			if (!plantingIds.has(plantingId)) {
				delete state.sizingGuides[plantingId];
			}
		});

		state.lastSync = new Date(); // Also notates that data has loaded
	},
};

const actions = {
	setOnline: ({ commit }: any, isOnline: any) => commit("setOnline", isOnline),
	setCurrentLocation: ({ commit }: any, currentLocation: any) => commit("setCurrentLocation", currentLocation),
	addToOfflineQueue: ({ commit }: any, payload: any) => commit("addToOfflineQueue", payload),
	removeFromOfflineQueue: ({ commit }: any) => commit("removeFromOfflineQueue"),
	login: ({ commit }: any, payload: { needsToSignAgreement: any; permissions: any }) => {
		commit("logout"); // make sure state is cleared to avoid showing wrong customer plantings while demoing and switching customers.
		commit("setNeedsToSignAgreement", payload.needsToSignAgreement);
		commit("setPermissions", payload.permissions);
		commit("login");
	},
	updateUserAgreement: ({ commit }: any) => commit("updateUserAgreement"),
	deleteAlert: ({ commit }: any, alertId: any) => commit("deleteAlert", alertId),
	clearAlerts: ({ commit }: any) => commit("clearAlerts"),
	commitIrrigation: ({ commit }: any, payload: any) => commit("commitIrrigation", payload),
	createIrrigation: ({ commit }: any, payload: any) => commit("createIrrigation", payload),
	updateIrrigation: ({ commit }: any, payload: any) => commit("updateIrrigation", payload),
	deleteIrrigation: ({ commit }: any, payload: any) => commit("deleteIrrigation", payload),
	commitIrrigationActivity: ({ commit }: any, payload: any) => commit("commitIrrigationActivity", payload),
	createIrrigationActivity: ({ commit }: any, payload: any) => commit("createIrrigationActivity", payload),
	updateIrrigationActivity: ({ commit }: any, payload: any) => commit("updateIrrigationActivity", payload),
	deleteIrrigationActivity: ({ commit }: any, payload: any) => commit("deleteIrrigationActivity", payload),
	commitSoilSampleTask: ({ commit }: any, payload: any) => commit("commitSoilSampleTask", payload),
	createSoilSampleTask: ({ commit }: any, payload: any) => commit("createSoilSampleTask", payload),
	updateSoilSampleTask: ({ commit }: any, payload: any) => commit("updateSoilSampleTask", payload),
	deleteSoilSampleTask: ({ commit }: any, payload: any) => commit("deleteSoilSampleTask", payload),
	commitChemicalTask: ({ commit }: any, payload: any) => commit("commitChemicalTask", payload),
	createChemicalTask: ({ commit }: any, payload: any) => commit("createChemicalTask", payload),
	updateChemicalTask: ({ commit }: any, payload: any) => commit("updateChemicalTask", payload),
	deleteChemicalTask: ({ commit }: any, payload: any) => commit("deleteChemicalTask", payload),
	addNote: ({ commit }: any, note: any) => commit("addNote", note),
	deleteNote: ({ commit }: any, note: any) => commit("deleteNote", note),
	editNote: ({ commit }: any, note: any) => commit("editNote", note),
	commitNote: ({ commit }: any, payload: any) => commit("commitNote", payload),
	addNoteImage: ({ commit }: any, payload: any) => commit("addNoteImage", payload),
	clearUserStatistic: ({ commit }: any, payload: any) => commit("clearUserStatistic", payload),
	clearUserStatistics: ({ commit }: any, plantingId: any) => commit("clearUserStatistics", plantingId),
	updateUserStatistic: ({ commit }: any, payload: any) => commit("updateUserStatistic", payload),
	setUserStatistics: ({ commit }: any, payload: any) => commit("setUserStatistics", payload),
	updateActualStatistic: ({ commit }: any, payload: any) => commit("updateActualStatistic", payload),
	updateBatchStatistics: ({ commit }: any, payload: any) => commit("updateBatchStatistics", payload),
	updateHarvestAcres: ({ commit }: any, payload: any) => commit("updateHarvestAcres", payload),
	commitRanchYield: ({ commit }: any, payload: any) => commit("commitRanchYield", payload),
	createRanchYield: ({ commit }: any, payload: any) => commit("createRanchYield", payload),
	putTask: ({ commit }: any, payload: any) => commit("putTask", payload),
	setHarvestAcres: ({ commit }: any, payload: any) => commit("setHarvestAcres", payload),
	setIrrigationBlocks: ({ commit }: any, payload: any) => commit("setIrrigationBlocks", payload),
	createOrUpdateTaskRequests: ({ commit }: any, payload: any) => commit("createOrUpdateTaskRequests", payload),
	savePlantingTicketRecord: ({ commit }: any, payload: any) => commit("savePlantingTicketRecord", payload),
	deletePlantingTicketRecord: ({ commit }: any, payload: any) => commit("deletePlantingTicketRecord", payload),
	deletePlantingTicketDrafts: ({ commit }: any, payload: any) => commit("deletePlantingTicketDrafts", payload),
	submitPlantingTicketRecord: ({ commit }: any, payload: any) => commit("submitPlantingTicketRecord", payload),
	revertSubmitPlantingTicketRecord: ({ commit }: any, payload: any) =>
		commit("revertSubmitPlantingTicketRecord", payload),
	updatePlantingAfterSubmitTicket: ({ commit }: any, payload: any) =>
		commit("updatePlantingAfterSubmitTicket", payload),
	setRanches: ({ commit }: any, payload: any) => commit("setRanches", payload),
	setRanchCoolers: ({ commit }: any, payload: any) => commit("setRanchCoolers", payload),
	setSeasons: ({ commit }: any, payload: any) => commit("setSeasons", payload),
	setHeadSizeColorMap: ({ commit }: any, payload: any) => commit("setHeadSizeColorMap", payload),
	tasksFiltersChanged: ({ commit }: any, payload: any) => commit("tasksFiltersChanged", payload),
	irrigationFiltersChanged: ({ commit }: any, payload: any) => commit("irrigationFiltersChanged", payload),
	loadPlantingDistribution: ({ commit }: any, payload: any) => commit("loadPlantingDistribution", payload),
	logout: ({ commit }: any) => commit("logout"),
	setLocale: ({ commit }: any, payload: any) => commit("setLocale", payload),
	setLastSync: ({ commit }: any, payload: Date | null) => commit("setLastSync", payload),
	resync: ({ commit }: any, data: any) => commit("resync", data),
};

const getters = {
	newWeekOfYear: (state: { yearWeekType: string }) => (arg1: any, arg2: any, arg3: any) => {
		switch (state.yearWeekType) {
			case "Iso":
				return new IsoWeekOfYear(arg1, arg2, arg3);
			case "Jan1":
				return new Jan1WeekOfYear(arg1, arg2, arg3);
			case "Aug28":
				return new Aug28WeekOfYear(arg1, arg2, arg3);
			default:
				console.warn("No such week type: " + state.yearWeekType);
				return new IsoWeekOfYear(arg1, arg2, arg3);
		}
	},
	getPlantingName: (state: { regions: any[] }) => (id: any) => {
		const planting = state.regions.find((region: { id: any }) => region.id == id);
		return (planting && planting.agriculturalArea + ": " + planting.plantingRegion) || "";
	},
	getLotName: (state: { lotViewsById: { [x: string]: any } }) => (uid: string | number) => {
		const lot = state.lotViewsById[uid];
		return lot?.fullName || "";
	},
	getSpatialEventName:
		(state: any, getters: { getPlantingName: (arg0: any) => any; getLotName: (arg0: any) => any }) =>
		(spatialEvent: {
			boundaryType: any;
			plantingIds: any[];
			lotUids: any[];
			ranchId: any;
			irrigationBlockId: any;
		}) => {
			if (!spatialEvent) return;

			if (spatialEvent.ranchId)
				return state.ranches.find((r: { id: any }) => r.id === spatialEvent.ranchId)?.name;
			else if (spatialEvent.irrigationBlockId)
				return state.getIrrigationBlockName(spatialEvent.irrigationBlockId);
			else if (spatialEvent.lotUids.length)
				return spatialEvent.lotUids.map((luid: any) => getters.getLotName(luid)).join(", ");
			else if (spatialEvent.plantingIds.length)
				return spatialEvent.plantingIds.map((pid: any) => getters.getPlantingName(pid)).join(", ");

			return undefined;
		},
	getIrrigationBlockName:
		(state: { irrigationBlocksById: { [x: string]: any }; regions: any[] }) => (id: string | number) => {
			const block = state.irrigationBlocksById[id];
			if (!block) {
				console.debug("missing block", id, state.irrigationBlocksById);
				return "";
			}

			let name = "";
			if (block.plantingIds.length) {
				const planting = state.regions.find((region: { id: any }) => region.id == block.plantingIds[0]);
				if (planting) {
					name += planting.agriculturalArea + ": ";
				}
			}
			return name + block.name;
		},
	visibleBinTypes: (state: { binTypes: any[] }) =>
		state.binTypes
			.filter((x: { chartColor: any }) => !!x.chartColor)
			.sort((a: { displayOrder: number }, b: { displayOrder: number }) => a.displayOrder - b.displayOrder),
	getUserDisplayName: (state: { displayUsersById: { [x: string]: { name: any } } }) => (id: string | number) =>
		state.displayUsersById[id]?.name || "~",
	onlineColor: (state: { online: any }) => (state.online ? "branding" : "dark"),
};

export const storeConfig = {
	state: getDefaultState(),
	getters: getters as StoreOptions<any>["getters"], // FIXME:
	actions,
	mutations: mutations as StoreOptions<any>["mutations"], // FIXME:
	plugins: process.env.NODE_ENV !== "test" ? [vuexPersist.plugin, OfflineQueue.plugin] : [],
};

const store = createStore(storeConfig);

// Offline/online tracking
// TODO: Move to separate file
window.addEventListener("online", function () {
	store.dispatch("setOnline", true);
});

window.addEventListener("offline", function () {
	store.dispatch("setOnline", false);
});

if (store.state.online && store.state.offlineQueue?.length) {
	OfflineQueue.process(store);
}

// Resync to ensure that data does not get stale
BackgroundSync.run();

export default store;
